export const LightBack = ({ containerWidth, ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={containerWidth * 0.6761658}
    // height={184}
    viewBox="0 0 522 184"
    {...rest}
  >
    <path
      fill={rest?.color || "#1A8EB2"}
      fillRule="evenodd"
      d="M79.706 183.39V33.73L94.878 9.025 96.295.652l1.366 8.375 13.09 24.593v149.77m163.464 0V49.928h26.083V183.39m8.655 0v-82.856h28.118v82.856m-99.14 0V36.035h28.118V183.39m-65.65 0V65.293h28.118V183.39m134.606 0V87.472h62.115l.868 95.918m-425.486 0V54.305h71.938l1.005 129.085m374.142 0L446.44 59.498h10.109v-9.841h9.334V39.67h8.729V29.181h16.567v8.26h7.848v12.487h9.885v8.046h12.432l.322 125.416"
    />
  </svg>
);

export const MediumMiddle = ({ containerWidth, ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={containerWidth * 0.89637306}
    // height={118}
    viewBox="0 0 692 118"
    {...rest}
    style={{ marginTop: containerWidth * 0.08678756 + 2.5 }}
  >
    <path
      fill={rest?.color || "#1A8EB2"}
      fillRule="evenodd"
      d="M.86 117.39V45.855h45.032v71.535m50.09 0V3.13h58.237v114.26m44.146 0V1.167L234.69.886V117.39m5.236 0V66.338h9.163l11.37-9.164V45.391h12.022v7.03h-7.428v4.753l12.835 9.164h7.854v51.052m15.061 0V65.312h20.91v52.078m6.502 0V59.802h20.942v57.588m53.479 0V61.391l3.905-3.905V46.693h26.1v9.334l5.037 5.037v56.326m131.964 0V61.058h38.829v56.332m-251.895 0V32.914h38.829v84.476m-344.28 0V61.058h38.828v56.332m558.158 0V70.838h43.315v46.552m-170.346 0V70.838h43.315v46.552"
    />
  </svg>
);

export const DarkFront = ({ containerWidth, ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={containerWidth}
    // height={57}
    viewBox="0 0 772 57"
    {...rest}
    style={{ marginTop: containerWidth * 0.16580311 + 5 }} // 83
  >
    <path
      fill={rest?.color || "#182130"}
      fillRule="evenodd"
      d="M104.882 56.39V.546h41.501V56.39m6.07 0V19.737H188.9v19.636h20.459V56.39m8.732 0V24.973h24.684s-.095-24.217 0-24.217c.188 0 30.199-.21 30.199-.21v12.362h25.587v12.065h10.909V56.39m18.989 0V24.973h57.88V56.39m44.258 0V39.66h26.382v4.164h29.185V56.39m14.376 0V21.78h35.079v34.61m73.475 0V21.78h35.078v34.61m-92.518 0V.546h41.78V56.39m53.718 0V39.66h31.563v16.73m13.608 0V29.78l79.389-.273V56.23M.573 56.39V27.591h51.455v28.8"
    />
  </svg>
);
