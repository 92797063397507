import { Hero } from "./Hero";
import { Services } from "./Services";
import { SkylineCTA } from "./SkylineCTA";

export const HomePage = ({ IDENTITY_INFO }) => {
  return (
    <>
      <Hero IDENTITY_INFO={IDENTITY_INFO} />
      <Services IDENTITY_INFO={IDENTITY_INFO} />
      <SkylineCTA IDENTITY_INFO={IDENTITY_INFO} />
    </>
  );
};
