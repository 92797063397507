import React from "react";
import {
  Container,
  Title,
  SimpleGrid,
  Text,
  useMantineTheme,
  Button,
  Center,
  Image,
} from "@mantine/core";
import { Link } from "react-router-dom";

export const Services = ({ IDENTITY_INFO }) => {
  const theme = useMantineTheme();
  const { colorScheme } = theme;
  const dark = colorScheme === "dark";

  return (
    <Container pt={theme.other.spacing} pb={theme.other.spacing}>
      <Title order={2} size="h1" align="center" mb="0.5em">
        {IDENTITY_INFO.services.title}
      </Title>

      <SimpleGrid
        cols={2}
        spacing="xl"
        breakpoints={[{ maxWidth: "xs", cols: 1, spacing: "sm" }]}
      >
        {IDENTITY_INFO.services.services.map((service, i) => {
          if (service.servicesPageOnly) return null;
          return (
            <div style={{ marginTop: "1em" }} key={i}>
              <Image
                src={service.image}
                radius="sm"
                withPlaceholder
                alt={`${service.title} services image`}
                aria-label={`${service.title} services image`}
                styles={{ image: { minHeight: 160 } }}
              />
              <Title order={3} mt="1em" mb="0.5em">
                {service.title}
              </Title>
              <Text>{service.desc}</Text>
            </div>
          );
        })}
      </SimpleGrid>

      <Center mt="3em">
        <Button
          component={Link}
          to="/services"
          mt="1rem"
          sx={{
            backgroundColor: dark
              ? theme.colors.offBlack
              : theme.colors.offWhite,
            color: dark ? theme.colors.white : theme.colors.black,
            transition: `all 100ms ease-in-out 0ms`,
            "&:hover": {
              backgroundColor: dark
                ? theme.colors.offWhite
                : theme.colors.white,
              color: theme.colors.black,
              boxShadow: theme.shadows.lg,
            },
          }}
          leftIcon={React.createElement(IDENTITY_INFO.services.buttonIcon, {
            size: "1em",
            "aria-label": "More icon",
          })}
        >
          Browse All Services
        </Button>
      </Center>
    </Container>
  );
};
