import {
  Container,
  Center,
  Title,
  // Image,
  Button,
  useMantineTheme,
  useMantineColorScheme,
  Text,
} from "@mantine/core";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
// import meme from "../../img/not-found-meme.jpg";
import { IconHome } from "@tabler/icons";

export const NotFound = ({ IDENTITY_INFO }) => {
  const theme = useMantineTheme();
  const { colorScheme } = useMantineColorScheme();
  const dark = colorScheme === "dark";

  return (
    <>
      <Helmet>
        <title>Not Found — {IDENTITY_INFO.meta.businessName}</title>
        <meta name="description" content={IDENTITY_INFO.seo.notFound} />
      </Helmet>

      <Container>
        <Center
          sx={{
            width: "100%",
            height: `80vh`,
            position: "relative",
            flexDirection: "column",
            marginTop: `10vh`,
          }}
        >
          <Title order={2} size="h1" align="center">
            <span style={{ fontWeight: 200 }}>404</span> Page Not Found
          </Title>

          <Text mt="1em" align="center" style={{ whiteSpace: "pre" }}>
            {IDENTITY_INFO.notFound}
          </Text>

          <Button
            component={Link}
            to="/"
            mt="1em"
            mb="1em"
            sx={{
              backgroundColor: dark
                ? theme.colors.offBlack
                : theme.colors.offWhite,
              color: dark ? theme.colors.white : theme.colors.black,
              transition: `all 100ms ease-in-out 0ms`,
              "&:hover": {
                backgroundColor: dark
                  ? theme.colors.offWhite
                  : theme.colors.white,
                color: theme.colors.black,
                boxShadow: theme.shadows.lg,
              },
            }}
            leftIcon={<IconHome size="1em" aria-label="Navigate home icon" />}
          >
            Go Home
          </Button>

          {/* <Image
          src={meme}
          radius="sm"
          withPlaceholder
          style={{ maxWidth: 640, marginLeft: "auto", marginRight: "auto" }}
        /> */}
        </Center>
      </Container>
    </>
  );
};
