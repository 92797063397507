import {
  Center,
  Title,
  Container,
  useMantineTheme,
  Button,
  Accordion,
  Text,
} from "@mantine/core";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useViewportSize } from "@mantine/hooks";
import {
  IconCornerUpRight,
  IconArrowRight,
  IconCornerDownLeft,
} from "@tabler/icons";

export const ApproachPage = ({ IDENTITY_INFO }) => {
  const theme = useMantineTheme();
  const { width } = useViewportSize();

  const titleSize =
    width / 20 + 8 < 24 ? 24 : width / 20 + 8 > 72 ? 72 : width / 20 + 8;

  return (
    <>
      <Helmet>
        <title>Approach — {IDENTITY_INFO.meta.businessName}</title>
        <meta name="description" content={IDENTITY_INFO.seo.approach} />
      </Helmet>

      <Center
        pt={`calc(2em + ${theme.other.spacingHeader})`}
        pb={theme.other.spacingHeader}
        style={{ flexDirection: "column" }}
      >
        <Title
          order={2}
          mt="1em"
          size={titleSize}
          align="center"
          style={{ textTransform: "uppercase" }}
        >
          <IconCornerUpRight
            size="1em"
            style={{ verticalAlign: "sub" }}
            aria-label="Approach diagram arrow"
          />
          &nbsp;
          {IDENTITY_INFO.approach.steps[0].title}&nbsp;
          <IconArrowRight
            size="1em"
            style={{ verticalAlign: "sub" }}
            aria-label="Approach diagram arrow"
          />{" "}
          {IDENTITY_INFO.approach.steps[1].title}{" "}
          <IconArrowRight
            size="1em"
            style={{ verticalAlign: "sub" }}
            aria-label="Approach diagram arrow"
          />{" "}
          {IDENTITY_INFO.approach.steps[2].title}&nbsp;
          <IconArrowRight
            size="1em"
            style={{ verticalAlign: "sub" }}
            aria-label="Approach diagram arrow"
          />
          &nbsp;{IDENTITY_INFO.approach.steps[3].title}&nbsp;
          <IconCornerDownLeft
            size="1em"
            style={{ verticalAlign: "sub" }}
            aria-label="Approach diagram arrow"
          />
        </Title>
      </Center>

      <Container pt={theme.other.spacing} pb={theme.other.spacing}>
        <Title order={2} size="h3" mb="1em" style={{ opacity: 0.5 }}>
          OUR APPROACH:
        </Title>
        <Accordion
          variant="separated"
          defaultValue={IDENTITY_INFO.approach.steps[0].title}
          pb={theme.other.spacing}
        >
          {IDENTITY_INFO.approach.steps.map((thisStep, i) => (
            <Accordion.Item value={thisStep.title} key={i}>
              <Accordion.Control>
                <Title order={3} size="h2">
                  {thisStep.title}
                </Title>
              </Accordion.Control>
              <Accordion.Panel>
                <Text>{thisStep.desc}</Text>
              </Accordion.Panel>
            </Accordion.Item>
          ))}
        </Accordion>
      </Container>

      <MissionStatement IDENTITY_INFO={IDENTITY_INFO} />
    </>
  );
};

export const MissionStatement = ({ IDENTITY_INFO, showCTA = true }) => {
  const theme = useMantineTheme();
  const { colorScheme } = theme;
  const dark = colorScheme === "dark";
  const { width } = useViewportSize();

  const statementSize = width / 100 + 14.8; // base: 34px

  return (
    <Container pt={theme.other.spacing} pb={theme.other.spacing}>
      <Center style={{ flexDirection: "column" }}>
        <Title order={2} size={statementSize} align="center">
          {IDENTITY_INFO.cta.missionStatement}
        </Title>

        {showCTA && (
          <Button
            component={Link}
            to="/contact"
            mt="1.5em"
            sx={{
              backgroundColor: dark
                ? theme.colors.offBlack
                : theme.colors.offWhite,
              color: dark ? theme.colors.white : theme.colors.black,
              transition: `all 100ms ease-in-out 0ms`,
              marginLeft: "auto",
              marginRight: "auto",
              "&:hover": {
                backgroundColor: dark
                  ? theme.colors.offWhite
                  : theme.colors.white,
                color: theme.colors.black,
                boxShadow: theme.shadows.lg,
              },
            }}
          >
            Book {IDENTITY_INFO.services.ctaSectionButton} Chat
          </Button>
        )}
      </Center>
    </Container>
  );
};
