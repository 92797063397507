import {
  Center,
  Title,
  Container,
  SimpleGrid,
  Box,
  Image,
  Text,
  useMantineTheme,
  Button,
} from "@mantine/core";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useViewportSize } from "@mantine/hooks";

export const ServicesPage = ({ IDENTITY_INFO }) => {
  const theme = useMantineTheme();
  const { colorScheme } = theme;
  const dark = colorScheme === "dark";
  const { width } = useViewportSize();

  const titleSize = width / 20 + 20 > 38 ? width / 20 + 20 : 38; // baseline: 116px
  const ctaSize = width / 100 + 14.8; // base: 34px

  return (
    <>
      <Helmet>
        <title>Services — {IDENTITY_INFO.meta.businessName}</title>
        <meta name="description" content={IDENTITY_INFO.seo.services} />
      </Helmet>

      <Container
        pt="8em"
        pb={theme.other.spacingHeader}
        style={{ flexDirection: "column" }}
      >
        <Title
          order={2}
          size={titleSize}
          align="center"
          sx={{
            "&:first-letter": { textTransform: "uppercase" },
          }}
        >
          {IDENTITY_INFO.meta.focusNoun} Services
        </Title>
        <Text align="center" mt="0.5em">
          From start to scale, {IDENTITY_INFO.meta.businessName} is built to
          help your {IDENTITY_INFO.meta.focusNoun} at any stage of launch{" "}
          <em>or</em>
          &nbsp;growth.
        </Text>
      </Container>

      <Container>
        <SimpleGrid
          cols="2"
          spacing="xl"
          pb="6em"
          breakpoints={[{ maxWidth: "xs", cols: 1, spacing: "sm" }]}
        >
          {IDENTITY_INFO.services.services.map((thisService, i) => (
            <Box mt="2em" key={i}>
              <Image
                src={thisService?.image}
                withPlaceholder
                alt={`${thisService.title} service image`}
                radius="sm"
                styles={{ image: { minHeight: 160 } }}
              />
              <Title order={4} size="h3" mt="0.5em" mb="0.25em">
                {thisService.title}
              </Title>
              <Text>{thisService.desc}</Text>
            </Box>
          ))}
        </SimpleGrid>

        <Center
          style={{ flexDirection: "column" }}
          pt={theme.other.spacing}
          pb={theme.other.spacing}
        >
          <Title
            order={2}
            size={ctaSize}
            align="center"
            mb="1em"
            style={{ maxWidth: 760 }}
          >
            {IDENTITY_INFO.services.ctaSectionTitle}
          </Title>

          <Button
            component={Link}
            to="/contact"
            sx={{
              backgroundColor: dark
                ? theme.colors.offBlack
                : theme.colors.offWhite,
              color: dark ? theme.colors.white : theme.colors.black,
              transition: `all 100ms ease-in-out 0ms`,
              marginLeft: "auto",
              marginRight: "auto",
              "&:hover": {
                backgroundColor: dark
                  ? theme.colors.offWhite
                  : theme.colors.white,
                color: theme.colors.black,
                boxShadow: theme.shadows.lg,
              },
            }}
          >
            Book {IDENTITY_INFO.services.ctaSectionButton} Chat
          </Button>
        </Center>
      </Container>
    </>
  );
};
