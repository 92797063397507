import { useLayoutEffect } from "react";
import {
  Container,
  Title,
  useMantineTheme,
  Button,
  Center,
  Box,
  Stack,
  Badge,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { Link } from "react-router-dom";
import Parallax from "parallax-js";
import { LightBack, MediumMiddle, DarkFront } from "../../img/cityLayers";

export const SkylineCTA = ({ IDENTITY_INFO }) => {
  const theme = useMantineTheme();
  const { colorScheme } = theme;
  const dark = colorScheme === "dark";

  return (
    <Container
      pt={theme.other.spacing}
      pb={theme.other.spacing}
      style={{ overflow: "hidden" }}
    >
      <Skyline />

      <Title order={2} size="h1" align="center">
        Let's transform your&nbsp;{IDENTITY_INFO.cta.titleNoun}.
      </Title>
      <Center>
        <Button
          mt="1.5em"
          component={Link}
          to="/contact"
          sx={{
            backgroundColor: dark
              ? theme.colors.offBlack
              : theme.colors.offWhite,
            color: dark ? theme.colors.white : theme.colors.black,
            transition: `all 100ms ease-in-out 0ms`,
            marginLeft: "auto",
            marginRight: "auto",
            "&:hover": {
              backgroundColor: dark
                ? theme.colors.offWhite
                : theme.colors.white,
              color: theme.colors.black,
              boxShadow: theme.shadows.lg,
            },
          }}
        >
          Book a 30 Minute {IDENTITY_INFO.cta.ctaButtonNoun}
        </Button>
      </Center>
    </Container>
  );
};

const Skyline = () => {
  const theme = useMantineTheme();
  const { colorScheme } = theme;
  const dark = colorScheme === "dark";

  const fullsize = useMediaQuery("(min-width: 768px)");

  useLayoutEffect(() => {
    let scene = document.getElementById("city");

    // this.parallaxInstance = new Parallax(scene, {
    // const theLax =
    new Parallax(scene, {
      frictionX: 0.03,
      frictionY: 0,
      originX: 0.5,
    });
  }, []);

  const layerStyles = { marginLeft: "auto", marginRight: "auto" };

  const theWidth = fullsize ? 772 : 500;

  return (
    <Center mb={theme.other.spacingHeader}>
      <Box
        id="city"
        style={{
          borderBottom: `1px solid ${
            dark ? theme.colors.offBlack : theme.colors.offWhite
          }`,
          width: theWidth,
          minWidth: theWidth,
          maxWidth: theWidth,
        }}
      >
        <LightBack
          data-depth="0.1"
          style={{ ...layerStyles }}
          color={
            dark
              ? theme.fn.lighten(theme.colors.offBlack, 0.05)
              : theme.fn.darken(theme.colors.offWhite, 0.05)
          }
          containerWidth={theWidth}
          aria-label="Visual element of Raleigh NC skyline image"
        />
        <MediumMiddle
          data-depth="0.2"
          style={{ ...layerStyles }}
          color={
            dark
              ? theme.fn.lighten(theme.colors.offBlack, 0.2)
              : theme.fn.darken(theme.colors.offWhite, 0.2)
          }
          containerWidth={theWidth}
          aria-label="Visual element of Raleigh NC skyline image"
        />
        <DarkFront
          data-depth="0.3"
          style={{ ...layerStyles }}
          color={
            dark
              ? theme.fn.lighten(theme.colors.offBlack, 0.35)
              : theme.fn.darken(theme.colors.offWhite, 0.35)
          }
          containerWidth={theWidth}
          aria-label="Visual element of Raleigh NC skyline image"
        />
        <Stack
          align="flex-end"
          style={{
            width: "100%",
            height: "100%",
            flexDirection: "column",
            alignContent: "flex-end",
          }}
        >
          <Badge
            align="center"
            color="gray"
            style={{
              position: "absolute",
              bottom: "-3.2em",
              left: "50%",
              transform: "translateX(-50%)",
            }}
            size="sm"
            aria-label="Oak City Raleigh, NC"
          >
            Oak City &bull; Raleigh, NC
          </Badge>
        </Stack>
      </Box>
    </Center>
  );
};
