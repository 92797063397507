import { IconSquareRoundedPlus, IconCodePlus } from "@tabler/icons";

export const IDENTITIES = Object.freeze({
  REASONSPARK: "ReasonSpark",
  AVALITH: "Avalith",
});

export const IDENTITY_PROFILES = Object.freeze({
  [IDENTITIES.REASONSPARK]: {
    mainLogo: require("./img/reasonspark-logo").ReasonsparkLogo,
    squareLogo: require("./img/reasonspark-logo").Bulb,
    meta: {
      businessName: "ReasonSpark",
      siteTitle: "ReasonSpark — Branding + Web Design — Raleigh, NC",
      siteDescription:
        "Branding, positioning, strategy, design, and code for companies ready to start or ready to scale. Based in the Research Triangle of Raleigh, North Carolina.",
      focusNoun: "brand",
      domain: "reasonspark.com",
      gaTagId: `G-K4BZ66HJCN`, // G-A0AA00AAAA
    },
    seo: {
      keywords:
        "Branding, Positioning, Illustration, Copywriting, Design, Logo Design, Raleigh, Research Triangle, North Carolina",
      serivces:
        "From start to scale, ReasonSpark is built to help your brand at any stage of launch or growth.",
      approach: "",
      contact: "",
      notFound: "",
    },
    tagline: {
      topText: "Design Agency",
      bottomText: "Brand Studio",
      subtitle: `We transform companies into memorable, profitable\u00a0brands.`,
      ctaNoun: "Brand Audit",
      dotColor: "orange",
    },
    services: {
      title: "We can help at any\u00a0stage.",
      buttonIcon: IconSquareRoundedPlus,
      services: [
        {
          title: "Positioning",
          desc: `Our Brand Positioning services help companies define their unique position in the minds of their customers.  We work closely with our clients to craft a clear and consistent brand statement that effectively communicates their value proposition. From developing brand names, to creating positioning statements, to conducting research and design, we help ensure that your brand's identity is unified both internally and externally. Let us help you establish a crisp brand position that will set you apart from the competition.`,
          image: require("./img/services/rs-positioning.jpg"),
          servicesPageOnly: false,
        },
        {
          title: "Branding",
          desc: `Our Branding services are dedicated to helping companies create a cohesive and professional visual identity. We understand that a brand without a clear and consistent design system can come across as chaotic and disorganized. That's why we work to define and cultivate the edges of your brand's logo, visual design, mission, and tone of voice using a smart and homogenous design system. This approach establishes a strong brand identity and provides control over even the smallest details, resulting in a polished brand image that stands out in the minds of your customers.`,
          image: require("./img/services/rs-branding.jpg"),
          servicesPageOnly: false,
        },
        {
          title: "Web Design",
          desc: `Our Web Design service specializes in creating visually stunning and functional websites and applications designed to achieve your business objectives. We understand the importance of a strong online presence and how it can impact the growth of your brand. We create websites that not only look great but also effectively guide users towards the desired end goal. We'll help you build a website that will take broadcasting your brand to the next level.`,
          image: require("./img/services/rs-webdesign.jpg"),
          servicesPageOnly: false,
        },
        {
          title: "Product Design",
          desc: `Our Product Design service is dedicated to working with product-driven companies to create innovative and user-centric solutions that meet the specific needs of their target market. Whether it's an app, shampoo or anything in between, we use the end-user's feedback to guide our process, resulting in exceptional user experiences and cohesive design systems. Let us help you create products that your customers will love.`,
          image: require("./img/services/rs-productdesign.jpg"),
          servicesPageOnly: false,
        },
        {
          title: "Illustration + Lettering",
          desc: `We understand that the smallest details can make a big impact on the overall look and feel of your brand. We take a thoughtful approach to crafting visually stunning illustrations and lettering that perfectly captures the mood and message of your company. Whether it's through a custom font or a unique illustration, we create designs that are both effortless and polished, ensuring that your brand is consistently represented in the best possible light, no matter where you're seen. We'll help you exemplify your brand's visual identity.`,
          image: require("./img/services/rs-illustrationandlettering.jpg"),
          servicesPageOnly: true,
        },
        {
          title: "Design Systems",
          desc: `Our Design System services offer a complete solution for elevating your brand's design standards. We work closely with our clients to create a unified design language, style guides, coded components, and brand languages, all backed by thorough documentation. This approach results in faster builds, cohesive user experiences, and a shared understanding of design rationale across all teams. We'll help you establish a solid foundation for your brand's design and ensure consistency across all touchpoints. Let us uplift and define your brand's design.`,
          image: require("./img/services/rs-designsystems.jpg"),
          servicesPageOnly: true,
        },
      ],
      ctaSectionTitle: `Over a 30 minute chat, we can review your company, its brand, and ways we can make real\u00a0impact.`,
      ctaSectionButton: "a Brand",
    },
    approach: {
      steps: [
        {
          title: "Discover",
          desc: "Our discovery phase is a vital component of our brand services approach methodology. It is the foundation upon which all successful projects are built, regardless of their size. During this phase, we take the time to immerse ourselves in our clients' businesses and industries, in order to gain a thorough understanding of their needs, goals, and target audience. Our research and information gathering process is designed to be thorough and methodical, supported by thoughtful documentation that guides our team in the right direction. We believe that the better we understand our clients and their objectives, the more effectively we can serve them, and deliver results that exceed their expectations. Let us help you establish a solid foundation for your brand by starting with a comprehensive discovery process.",
        },
        {
          title: "Create",
          desc: "The create phase is where we bring your brand to life. Building on the foundation established during the discovery phase, we use a curated design system as a single point of truth and the product of our ideation process and brings your brand vision to reality. This approach allows us to maximize creativity and efficiency, while keeping the project on track and within a shorter time frame. We will work closely with you to create a comprehensive and cohesive visual identity for your brand, including elements such as logo, typography, color palette, imagery, and more. We'll help you create a brand that stands out and resonates with your target audience.",
        },
        {
          title: "Deploy",
          desc: "The deploy phase is where we bring your newly designed brand to the world. With a brand that is designed, documented, and ready to go, we begin deploying it in all mediums and arenas necessary. This includes creating and implementing brand guidelines, designing and developing websites, marketing materials, packaging, and more. We understand that a brand is a living entity and it needs to evolve and adapt over time. Our team will be there to support you throughout the process, ensuring that your brand stays relevant and resonates with your target audience. Let us help you bring your brand to the world with confidence.",
        },
        {
          title: "Measure",
          desc: "The measure phase is where we ensure that your brand is effective and resonates with your target audience. We use analytics and feedback to measure the effectiveness of the project and make any necessary changes to ensure that it stays relevant and on track. Our team will interpret reactions and behaviors, shaping our strategy and guiding this and future projects. We understand that a successful brand is not only about how it looks, but also how it performs. We continuously evaluate and improve the brand to ensure that it achieves the desired results. Let us help you monitor and measure the success of your brand and make data-driven decisions to keep it evolving and growing.",
        },
      ],
    },
    contact: {
      email: "hello@reasonspark.com",
    },
    cta: {
      missionStatement:
        "Our mission is to engineer success stories for our clients by recognizing the unique individuals and businesses behind them. We take our clients' concerns as our own and use a data-driven, solution-oriented approach to craft effective strategies. We believe that solid work empowers great things and we are committed to delivering results that exceed\u00a0expectations.",
      titleNoun: "company", // sentence case
      ctaButtonNoun: "Brand Chat", // title case
    },
    footer: {
      copyright: "ReasonSpark",
    },
    notFound:
      "The page you're looking for isn't here.\nLet's head back home and figure this thing out together.",
  },
  [IDENTITIES.AVALITH]: {
    mainLogo: require("./img/avalith-logo").AvalithLogo,
    meta: {
      businessName: "Avalith",
      siteTitle: "Avalith — Web + App Development — Raleigh, NC",
      siteDescription: "...",
      focusNoun: "idea",
      domain: "avalith.com",
      gaTagId: `G-`, // G-A0AA00AAAA
    },
    seo: {
      keywords: "",
      serivces:
        "From start to scale, Avalith is built to help your idea at any stage of launch or growth.",
      approach: "",
      contact: "",
      notFound: "",
    },
    tagline: {
      topText: "Code Factory",
      bottomText: "Engagement Studio",
      subtitle: "We transform ideas into memorable, profitable experiences.",
      ctaNoun: "Idea Consultation",
      dotColor: "blue",
    },
    services: {
      title: "We can help at any\u00a0phase.",
      buttonIcon: IconCodePlus,
      services: [
        {
          title: "Avalith Service 1",
          desc: `Lorem ipsum dolor sit amet...`,
          image: require("./img/services/av-service-1.jpg"),
          servicesPageOnly: false,
        },
        {
          title: "Service 2",
          desc: `Lorem ipsum dolor sit amet...`,
          image: require("./img/services/av-service-1.jpg"),
          servicesPageOnly: false,
        },
        {
          title: "Service 3",
          desc: `Lorem ipsum dolor sit amet...`,
          image: require("./img/services/av-service-1.jpg"),
          servicesPageOnly: false,
        },
        {
          title: "Service 4",
          desc: `Lorem ipsum dolor sit amet...`,
          image: require("./img/services/av-service-1.jpg"),
          servicesPageOnly: false,
        },
        {
          title: "Service 5",
          desc: `Lorem ipsum dolor sit amet...`,
          image: require("./img/services/av-service-1.jpg"),
          servicesPageOnly: true,
        },
        {
          title: "Service 6",
          desc: `Lorem ipsum dolor sit amet...`,
          image: require("./img/services/av-service-1.jpg"),
          servicesPageOnly: true,
        },
      ],
      ctaSectionTitle: `Over a 30 minute chat, we can review your idea, desired outcomes, and ways we can make real\u00a0impact.`,
      ctaSectionButton: "an Idea",
    },
    approach: {
      steps: [
        {
          title: "Discover",
          desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque eu purus ut sem porttitor mollis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut aliquet risus eget pretium condimentum. Cras eu dui condimentum, fermentum eros viverra, aliquam leo. Suspendisse enim eros, sagittis sit amet convallis quis, laoreet eu urna. Cras lobortis dapibus pretium. Sed vulputate elit sit amet tellus auctor pharetra. Suspendisse tempor rhoncus ornare.",
        },
        {
          title: "Create",
          desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque eu purus ut sem porttitor mollis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut aliquet risus eget pretium condimentum. Cras eu dui condimentum, fermentum eros viverra, aliquam leo. Suspendisse enim eros, sagittis sit amet convallis quis, laoreet eu urna. Cras lobortis dapibus pretium. Sed vulputate elit sit amet tellus auctor pharetra. Suspendisse tempor rhoncus ornare.",
        },
        {
          title: "Deploy",
          desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque eu purus ut sem porttitor mollis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut aliquet risus eget pretium condimentum. Cras eu dui condimentum, fermentum eros viverra, aliquam leo. Suspendisse enim eros, sagittis sit amet convallis quis, laoreet eu urna. Cras lobortis dapibus pretium. Sed vulputate elit sit amet tellus auctor pharetra. Suspendisse tempor rhoncus ornare.",
        },
        {
          title: "Measure",
          desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque eu purus ut sem porttitor mollis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut aliquet risus eget pretium condimentum. Cras eu dui condimentum, fermentum eros viverra, aliquam leo. Suspendisse enim eros, sagittis sit amet convallis quis, laoreet eu urna. Cras lobortis dapibus pretium. Sed vulputate elit sit amet tellus auctor pharetra. Suspendisse tempor rhoncus ornare.",
        },
      ],
    },
    contact: {
      email: "hello@avalith.com",
    },
    cta: {
      missionStatement: "[mission statement here]",
      titleNoun: "idea", // sentence case
      ctaButtonNoun: "Chat", // title case
    },
    footer: {
      copyright: `— A ReasonSpark Co.`,
    },
    notFound:
      "The page you're looking for isn't here.\nLet's head back home and figure this thing out together.",
  },
});
