import React from "react";
import { Container, Grid, Center, Text, useMantineTheme } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";

export const Footer = ({ IDENTITY_INFO, dark }) => {
  const theme = useMantineTheme();
  const xsSize = useMediaQuery("(max-width: 576px)");

  return (
    <Container pt={theme.other.spacing} pb="4em">
      <Grid
        align="center"
        gutter="xs"
        grow
        spacing={0}
        sx={{
          "@media (max-width: 575px)": {
            flexDirection: "column",
          },
        }}
      >
        <Grid.Col span={5} order={2} orderSm={1} orderXs={1}>
          <Text
            size={!xsSize ? "sm" : "xs"}
            color="dimmed"
            style={{ margin: "0 auto" }}
          >
            We reserve all them rights.
          </Text>
        </Grid.Col>
        <Grid.Col span={2} order={1} orderSm={2} orderXs={2}>
          <Center>
            {React.createElement(IDENTITY_INFO.squareLogo, {
              color: dark ? "#909296" : "#868e96",
              height: 36,
              "aria-label": `${IDENTITY_INFO.meta.businessName} logo`,
            })}
          </Center>
        </Grid.Col>
        <Grid.Col span={5} order={3} orderSm={3} orderXs={3}>
          <Text
            size={!xsSize ? "sm" : "xs"}
            color="dimmed"
            align="right"
            style={{ margin: "0 auto" }}
          >
            &copy; {new Date().getFullYear()} {IDENTITY_INFO.footer.copyright}
          </Text>
        </Grid.Col>
      </Grid>
    </Container>
  );
};
