import React from "react";
import {
  Header as MantineHeader,
  Group,
  ActionIcon,
  useMantineTheme,
  useMantineColorScheme,
  Button,
  Text,
} from "@mantine/core";
import { Link, useLocation } from "react-router-dom";
import { IconSunHigh, IconMoon } from "@tabler/icons";
import { routes } from "../App";

export const Header = ({ IDENTITY_INFO }) => {
  const theme = useMantineTheme();
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();
  const dark = colorScheme === "dark";
  let currentLocation = useLocation();

  return (
    <MantineHeader
      p={"lg"}
      style={{
        position: "absolute",
        top: 0,
        width: "100%",
        backgroundColor: dark
          ? `${theme.colors.black}cc`
          : `${theme.colors.white}e0`,
        borderBottom: "none",
        height: 20 + 36 + 20,
      }}
    >
      <Group
        position="apart"
        spacing="xl"
        noWrap
        style={{ position: "relative" }}
      >
        <MainLogo
          IDENTITY_INFO={IDENTITY_INFO}
          dark={dark}
          theme={theme}
          height={36}
          sx={{
            "@media (max-width: 767px)": {
              display: "none !important",
            },
          }}
          aria-label={`${IDENTITY_INFO.meta.businessName} logo`}
        />
        <Group
          noWrap
          sx={{
            "@media screen and (max-width: 767px)": {
              display: "none",
            },
          }}
        >
          <ActionIcon
            size="lg"
            variant="subtle"
            onClick={() => toggleColorScheme()}
            style={{ width: 36, height: 36 }}
            radius="xl"
            aria-label={`${dark ? "Light" : "Dark"} mode control`}
          >
            {dark ? (
              <IconSunHigh size="1.1em" aria-label="Sun icon" />
            ) : (
              <IconMoon size="1.1em" aria-label="Moon icon" />
            )}
          </ActionIcon>

          {routes.map((thisLink, i) => {
            if (!thisLink.mainNav) return null;
            return (
              <Text
                component={Link}
                to={thisLink.path}
                style={{
                  borderBottomStyle: "solid",
                  borderBottomWidth:
                    currentLocation.pathname === thisLink.path ? "1px" : "0px",
                }}
                key={i}
              >
                {thisLink.pageTitle}
              </Text>
            );
          })}

          <Button
            component={Link}
            to="/contact"
            size="sm"
            pl="md"
            pr="md"
            sx={{
              backgroundColor: dark
                ? theme.colors.offBlack
                : theme.colors.offWhite,
              color: dark ? theme.colors.white : theme.colors.black,
              transition: `all 100ms ease-in-out 0ms`,
              "&:hover": {
                backgroundColor: dark
                  ? theme.colors.offWhite
                  : theme.colors.white,
                color: theme.colors.black,
                boxShadow: theme.shadows.lg,
              },
            }}
          >
            Contact Us
          </Button>
        </Group>
      </Group>
    </MantineHeader>
  );
};

export const MainLogo = ({
  IDENTITY_INFO,
  dark,
  style,
  sx,
  onClick,
  height,
}) => (
  <Text
    component={Link}
    to="/"
    style={{ display: "inherit", ...style }}
    sx={{ ...sx }}
    onClick={onClick}
    align="left"
    aria-label={`${IDENTITY_INFO.meta.businessName} homepage link`}
  >
    <h1
      style={{ margin: "0 auto 0 0", display: "inherit" }}
      aria-label={`${IDENTITY_INFO.meta.businessName} logo`}
    >
      {React.createElement(IDENTITY_INFO.mainLogo, {
        color: dark ? "white" : "black",
        height: height || 36,
        "aria-label": `${IDENTITY_INFO.meta.businessName} logo`,
      })}
    </h1>
  </Text>
);
