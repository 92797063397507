import { useState } from "react";
import {
  Center,
  Title,
  Container,
  Group,
  useMantineTheme,
  Button,
  TextInput,
  Textarea,
  ThemeIcon,
  Text,
  Anchor,
} from "@mantine/core";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useViewportSize, useMediaQuery } from "@mantine/hooks";
import formData from "form-data";
import Mailgun from "mailgun.js";
import { IconCheck, IconMoodSad, IconSend } from "@tabler/icons";
import { useForm } from "@mantine/form";
import Obfuscate from "react-obfuscate";
import { MissionStatement } from "../approach";

export const ContactPage = ({ IDENTITY_INFO }) => {
  const theme = useMantineTheme();
  const { colorScheme } = theme;
  const dark = colorScheme === "dark";
  const { width } = useViewportSize();

  const xsSize = useMediaQuery("(max-width: 576px)");

  const titleSize = width / 30 + 15; // baseline: 116px

  const [formResponse, setFormResponse] = useState(undefined);

  const form = useForm({
    initialValues: {
      name: "",
      email: "",
      message: "",
    },

    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid email"),
    },
  });

  const mailgun = new Mailgun(formData);
  const mg = mailgun.client({
    key: `key-f67c69f8dbbb62b5718df65d199f2243`,
    username: `notify.reasonspark.com`,
  });

  const sendEmail = ({ name, email, message }) => {
    mg.messages
      .create(`notify.reasonspark.com`, {
        to: `mike@reasonspark.com,lindsay@reasonspark.com`,
        from: `website@reasonspark.com`,
        subject: `📝 New web form fill from ${IDENTITY_INFO.meta.domain}! 🎉`,
        html: `<h2>New web form fill from ${IDENTITY_INFO.meta.domain}!</h2>
<h3 style="margin-bottom:0;">Name:</h3>
<p style="margin-top:0;">${name}</p>

<h3 style="margin-bottom:0;">Email:</h3>
<p style="margin-top:0;">${email}</p>

<h3 style="margin-bottom:0;">How can we help?:</h3>
<p style="margin-top:0;margin-bottom:2em;">${message}</p>

<p style="margin-top:2em;">Happy prospecting!<br />— 🤖 The ${IDENTITY_INFO.meta.businessName} Website Robot`,
      })
      .then((msg) => {
        // notify success (or not) here
        console.log(msg);
        setFormResponse(msg);
      })
      .catch((err) => {
        // notify error here
        console.error(err);
        setFormResponse(err);
      });
  };

  return (
    <>
      <Helmet>
        <title>Contact — {IDENTITY_INFO.meta.businessName}</title>
        <meta name="description" content={IDENTITY_INFO.seo.contact} />
      </Helmet>

      <Center
        pt="10em"
        pb={theme.other.spacingHeader}
        style={{ flexDirection: "column" }}
      >
        <Text align="center" mb="0.5em">
          Let's talk about your&nbsp;{IDENTITY_INFO.meta.focusNoun}:
        </Text>
        <Title order={2} size={titleSize} align="center">
          <Obfuscate
            email={IDENTITY_INFO.contact.email}
            style={{ color: "inherit", textDecoration: "inherit" }}
          />
        </Title>
      </Center>

      <Container pb={theme.other.spacingHeader}>
        <form
          onSubmit={form.onSubmit(
            (values, _event) => {
              // console.log("valid", values, _event);
              sendEmail(values);
            },
            (validationErrors, _values, _event) => {
              console.error("invalid", validationErrors, _values, _event);
              // TODO: log error here
            }
          )}
        >
          {formResponse === undefined ? (
            <>
              <TextInput
                label="Name"
                size={!xsSize ? "lg" : "md"}
                required
                {...form.getInputProps("name")}
              />
              <TextInput
                label="Email"
                size={!xsSize ? "lg" : "md"}
                required
                {...form.getInputProps("email")}
                mt="1em"
              />
              <Textarea
                label="How can we help?"
                size={!xsSize ? "lg" : "md"}
                required
                mt="1em"
                minRows={5}
                {...form.getInputProps("message")}
              />
              <Group position="right">
                <Button
                  type="submit"
                  mt="1.5em"
                  leftIcon={
                    <IconSend size="1em" aria-label="Send message icon" />
                  }
                  sx={{
                    backgroundColor: dark
                      ? theme.colors.offBlack
                      : theme.colors.offWhite,
                    color: dark ? theme.colors.white : theme.colors.black,
                    transition: `all 100ms ease-in-out 0ms`,
                    marginLeft: "auto",
                    marginRight: 0,
                    "&:hover": {
                      backgroundColor: dark
                        ? theme.colors.offWhite
                        : theme.colors.white,
                      color: theme.colors.black,
                      boxShadow: theme.shadows.lg,
                    },
                  }}
                >
                  Send
                </Button>
              </Group>
            </>
          ) : formResponse?.status == 200 ? ( // eslint-disable-line eqeqeq
            <Center style={{ flexDirection: "column" }}>
              <Title order={2} size="h1">
                <ThemeIcon color="green">
                  <IconCheck />
                </ThemeIcon>{" "}
                Got It!
              </Title>
              <Text mt="1em" align="center">
                Thanks for your message! We'll review and reach out to you -
                usually within about a day.
              </Text>
              <Button
                component={Link}
                to="/"
                variant="light"
                color="gray"
                mt="2em"
              >
                Go Back Home
              </Button>
            </Center>
          ) : (
            <>
              <Center style={{ flexDirection: "column" }}>
                <Title order={2} size="h1">
                  <ThemeIcon color="red">
                    <IconMoodSad />
                  </ThemeIcon>{" "}
                  Hmm
                </Title>
                <Text mt="1em" align="center">
                  Something went wrong - but we want to make sure we get your
                  message. Please use the button below to send us an email, and
                  we'll respond in about a day.
                </Text>
                <Button
                  component={Anchor}
                  href={`mailto:${IDENTITY_INFO.contact.email}`}
                  variant="light"
                  color="gray"
                  mt="2em"
                >
                  {IDENTITY_INFO.contact.email}
                </Button>
              </Center>
            </>
          )}
        </form>
      </Container>

      <MissionStatement IDENTITY_INFO={IDENTITY_INFO} showCTA={false} />
    </>
  );
};
