import { useCallback } from "react";
import {
  Center,
  Title,
  Box,
  useMantineTheme,
  Button,
  Text,
} from "@mantine/core";
import { Link } from "react-router-dom";
import { useViewportSize } from "@mantine/hooks";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";

export const Hero = ({ IDENTITY_INFO }) => {
  const theme = useMantineTheme();
  const { colorScheme } = theme;
  const dark = colorScheme === "dark";
  const { width } = useViewportSize();

  const particlesInit = useCallback(async (engine) => {
    // console.log(engine);
    await loadFull(engine);
  }, []);

  const particlesConfig = {
    fpsLimit: 120,
    fullScreen: {
      enable: false,
    },
    interactivity: {
      events: {
        onClick: {
          enable: true,
          mode: "push",
        },
        onHover: {
          enable: false,
          mode: "attract",
        },
        resize: true,
      },
      modes: {
        push: {
          quantity: 1,
        },
        repulse: {
          distance: 150,
          duration: 0.4,
        },
        slow: {
          distance: 500,
        },
      },
    },
    particles: {
      color: {
        value: theme.colors[IDENTITY_INFO.tagline.dotColor],
      },
      links: {
        color: dark ? theme.colors.offWhite : theme.colors.offBlack,
        distance: 200,
        enable: true,
        opacity: 0.3,
        width: 1,
      },
      collisions: {
        enable: true,
      },
      move: {
        directions: "none",
        enable: true,
        outModes: {
          default: "bounce",
        },
        random: false,
        speed: 2,
        straight: false,
        acceleration: {
          min: 0,
          max: 0,
        },
      },
      number: {
        density: {
          enable: true,
          area: 800,
        },
        value: 40,
      },
      opacity: {
        value: dark ? 0.5 : 0.8,
      },
      shape: {
        type: "circle",
      },
      size: {
        value: { min: 1, max: 5 },
      },
    },
    detectRetina: true,
  };

  const mainTitleSize = width / 20 + 20 > 38 ? width / 20 + 20 : 38; // baseline: 116px
  const taglineSize = width * 0.005 + 12.4 > 14 ? width * 0.005 + 12.4 : 14; // baseline: 22px

  return (
    <Center
      sx={{
        width: "100%",
        height: `100vh`,
        position: "relative",
      }}
    >
      <Center
        spacing="xl"
        p="xl"
        sx={{
          flexDirection: "column",
          position: "relative",
          zIndex: 2,
          backgroundColor: dark
            ? `${theme.colors.black}cc`
            : `${theme.colors.white}e0`,
          borderRadius: theme.radius.sm,
        }}
      >
        <Title
          order={2}
          size={mainTitleSize}
          align="center"
          style={{ lineHeight: 0.9 }}
        >
          <strike
            style={{
              opacity: 0.5,
              fontWeight: 200,
              textDecorationThickness: `0.03448276em`, // 4px baseline
            }}
          >
            {IDENTITY_INFO?.tagline?.topText}
          </strike>
          <span style={{ fontWeight: "bold", display: "block" }}>
            {IDENTITY_INFO?.tagline?.bottomText}
          </span>
        </Title>
        <Text size={taglineSize} align="center" mt="1em" mb="1.5em">
          {IDENTITY_INFO?.tagline?.subtitle}
        </Text>

        <Button
          component={Link}
          to="/contact"
          sx={{
            backgroundColor: dark
              ? theme.colors.offBlack
              : theme.colors.offWhite,
            color: dark ? theme.colors.white : theme.colors.black,
            transition: `all 100ms ease-in-out 0ms`,
            marginLeft: "auto",
            marginRight: "auto",
            "&:hover": {
              backgroundColor: dark
                ? theme.colors.offWhite
                : theme.colors.white,
              color: theme.colors.black,
              boxShadow: theme.shadows.lg,
            },
          }}
        >
          Book a Free {IDENTITY_INFO.tagline.ctaNoun}
        </Button>
      </Center>

      <Box
        sx={{
          width: "100%",
          height: "100%",
          zIndex: 1,
          position: "absolute",
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
        }}
      >
        <Particles
          init={particlesInit}
          options={particlesConfig}
          style={{
            width: "100%",
            height: "100%",
            position: "absolute",
          }}
          aria-label="Particles effect canvas"
        />
      </Box>
    </Center>
  );
};
