export const AvalithLogo = ({ color, width, height, style }) => (
  <svg
    width={width || "auto"}
    height={height || null}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 313 50"
    style={style}
  >
    <defs>
      <path id="a" d="M23.38 33.936V.971H.051v32.965H23.38z" />
      <path id="c" d="M0 .157h33.856v48.182H0V.157z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g fill={color || "#292B34"}>
        <path d="M53.378 9.865h-9.242V24.05h9.242c4.35 0 7.314-2.966 7.314-7.117 0-4.152-2.964-7.068-7.314-7.068ZM51.401 26.62h-7.265v13.641h-2.817V7.295h12.306c5.684 0 10.033 3.608 10.033 9.638 0 5.98-4.25 9.291-9.143 9.49l9.539 13.838h-3.41l-9.243-13.64ZM69.042 27.115H86.39c-.05-4.053-2.719-9.094-8.699-9.094-5.634 0-8.452 4.942-8.649 9.094m8.698-11.318c7.266 0 11.269 5.782 11.269 12.652v.69H69.042c.247 5.19 3.707 9.492 9.341 9.492 3.014 0 5.783-1.137 7.808-3.313L87.526 37c-2.372 2.47-5.386 3.854-9.34 3.854-6.969 0-11.961-5.19-11.961-12.553 0-6.92 4.893-12.504 11.515-12.504M109.317 30.327c-1.68-2.175-4.349-3.312-7.216-3.312-3.953 0-6.523 2.522-6.523 5.932 0 3.36 2.57 5.88 6.523 5.88 2.867 0 5.536-1.087 7.216-3.261v-5.24Zm0 7.117c-2.125 2.323-4.794 3.41-8.104 3.41-4.103 0-8.353-2.817-8.353-7.958 0-5.19 4.2-7.907 8.353-7.907 3.31 0 6.03 1.087 8.104 3.41v-4.843c0-3.56-2.866-5.486-6.425-5.486-2.965 0-5.288 1.038-7.463 3.509l-1.432-1.78c2.47-2.668 5.238-4.002 9.192-4.002 4.844 0 8.698 2.322 8.698 7.66v16.805h-2.57v-2.818ZM117.865 35.17c1.532 1.927 4.35 3.51 7.71 3.51 4.003 0 6.375-1.978 6.375-4.745 0-3.065-3.262-3.954-6.672-4.844-4.003-.988-8.402-2.026-8.402-6.672 0-3.658 3.114-6.623 8.501-6.623 4.102 0 6.82 1.581 8.55 3.51l-1.433 1.827c-1.384-1.878-3.954-3.163-7.117-3.163-3.706 0-5.98 1.83-5.98 4.3 0 2.718 3.064 3.46 6.375 4.3 4.103 1.038 8.698 2.175 8.698 7.265 0 3.855-2.964 7.02-8.945 7.02-3.854 0-6.82-1.138-9.192-3.758l1.532-1.928ZM158.44 28.3c0-5.288-3.115-10.18-8.749-10.18-5.536 0-8.699 4.892-8.699 10.18 0 5.338 3.163 10.23 8.7 10.23 5.633 0 8.747-4.892 8.747-10.23m-20.215 0c0-6.969 4.498-12.504 11.467-12.504 7.067 0 11.516 5.535 11.516 12.504 0 6.97-4.449 12.554-11.516 12.554-6.969 0-11.467-5.585-11.467-12.554M182.455 24.1c0-4.597-2.323-5.98-5.782-5.98-3.065 0-6.228 1.927-7.76 4.101v18.04h-2.57V16.39h2.57v3.608c1.73-2.076 5.14-4.2 8.6-4.2 4.843 0 7.512 2.371 7.512 7.76V40.26h-2.57V24.1Z" />
      </g>
      <path
        d="M193.386 30.277a14.451 14.451 0 0 0 10.478 4.448c3.854 0 5.733-1.83 5.733-3.706 0-2.471-2.867-3.312-6.673-4.201-5.387-1.236-12.306-2.72-12.306-10.083 0-5.485 4.745-9.934 12.504-9.934 5.239 0 9.588 1.582 12.85 4.597l-3.904 5.14c-2.67-2.471-6.228-3.608-9.44-3.608-3.163 0-4.844 1.384-4.844 3.36 0 2.225 2.768 2.917 6.574 3.806 5.436 1.236 12.355 2.867 12.355 10.18 0 6.03-4.3 10.578-13.195 10.578-6.326 0-10.873-2.126-13.938-5.24l3.806-5.337ZM231.487 21.38c-2.026 0-4.349 1.188-5.387 2.719v8.45c1.038 1.484 3.361 2.72 5.387 2.72 3.608 0 6.03-2.817 6.03-6.97 0-4.101-2.422-6.918-6.03-6.918Zm-11.664 27.975V16.389h6.277v3.015c1.828-2.323 4.45-3.607 7.365-3.607 6.127 0 10.576 4.546 10.576 12.503s-4.449 12.554-10.576 12.554c-2.818 0-5.437-1.186-7.365-3.657v12.158h-6.277ZM261.582 31.562c-1.038-1.384-3.015-2.076-5.04-2.076-2.473 0-4.499 1.285-4.499 3.608 0 2.224 2.026 3.509 4.498 3.509 2.026 0 4.003-.691 5.041-2.075v-2.966Zm0 6.228c-1.63 1.927-4.448 3.064-7.562 3.064-3.805 0-8.303-2.57-8.303-7.907 0-5.586 4.498-7.612 8.303-7.612 3.164 0 5.981.989 7.562 2.916v-3.312c0-2.421-2.075-4.003-5.238-4.003-2.57 0-4.943.939-6.969 2.817l-2.373-4.201c2.916-2.57 6.673-3.756 10.43-3.756 5.435 0 10.427 2.175 10.427 9.045v15.42h-6.277V37.79ZM272.6 16.39h6.277v3.261c1.68-2.125 4.646-3.805 7.612-3.805v6.128a7.946 7.946 0 0 0-1.731-.198c-2.075 0-4.843 1.186-5.881 2.718v15.767H272.6V16.39Z"
        fill={color || "#292B34"}
      />
      <g transform="translate(288.855 6.325)">
        <path
          fill={color || "#292B34"}
          mask="url(#b)"
          d="M9.195 24.743 6.33 27.807v6.13H.051V.97H6.33v19.72l9.044-10.627h7.71l-9.44 10.824 9.737 13.048h-7.859z"
        />
      </g>
      <path
        fill="#0088ff"
        d="m18.006 27.717-.615.279-2.268 7.99 2.69-.695-.389 7.137.627-.483 1.757-9.298-2.572.728z"
      />
      <path
        d="M26.999 14.107a4.922 4.922 0 0 0-4.915-4.86 4.922 4.922 0 0 0-4.427 2.787c-.846-1.499-2.568-2.514-4.407-2.514-2.713 0-5.038 2.207-5.038 4.92v.025c-2.142.516-3.661 2.569-3.661 4.888 0 2.712 2.327 5.026 5.04 5.026h.266c.884 1.07 2.411 2.078 4.111 2.078 2.713 0 5.162-2.178 4.946-5.155v-.14l-.435.006-.018.25c0 2.084-2.374 3.663-4.457 3.663-2.085 0-4.495-1.773-4.495-3.913h-.37c-.125.685-.018 1.215.152 1.71-1.945-.153-3.482-1.715-3.482-3.697 0-2.084 1.794-3.746 3.877-3.746 2.083 0 4.52 1.734 4.52 3.817l.017.31h.41v-.315c0-2.711-2.266-4.917-4.977-4.92.063-2.03 1.698-3.782 3.744-3.782 2.084 0 3.665 2.036 3.976 3.666h.735v-.046c.35-2.27 1.927-3.757 4.01-3.757 2.083 0 3.74 1.662 3.74 3.803l.01.342v.728h.285c2.083 0 3.777 1.64 3.777 3.724 0 2.084-1.694 3.751-3.777 3.751-.917 0-1.906-.33-2.588-.84-.665-.497-1.396-1.198-1.443-2.889v-.535h-.363s-.244.704-.189 1.464c.047.851.244 1.606.8 2.237-.53 1.45-1.854 3.195-3.459 3.195v.434c2.14 0 3.66-1.16 4.446-2.827a4.9 4.9 0 0 0 2.794.874 4.925 4.925 0 0 0 4.92-4.92 4.922 4.922 0 0 0-4.075-4.842"
        fill={color || "#292B34"}
      />
      <g transform="translate(.538 .158)">
        <path
          d="m24.85 30.075-2.95 1.482h2.364l-1.512 10.225-5.865 4.475-5.865-4.475-1.48-10.225h2.474L9.03 30.075C4.377 27.278 1.6 22.392 1.6 17.012c0-8.416 6.882-15.264 15.34-15.264s15.337 6.849 15.337 15.265c0 5.38-2.776 10.265-7.428 13.062M16.93.157C7.591.157 0 7.717 0 17.007c0 5.808 2.93 11.086 7.857 14.205.045.246 1.297 6.913 1.73 10.3l.015.135.05.446.083.569 3.055 2.54c.803.66 1.443 1.17 1.941 1.563l.648.503.251.19.374.268.883.613.054-.035.592-.408.361-.254.213-.152.12-.09.618-.475c.486-.379 1.13-.892 1.966-1.576l3.234-2.684.082-.572.026-.232 1.862-10.67c4.907-3.12 7.84-8.39 7.84-14.183 0-9.291-7.588-16.851-16.927-16.851"
          fill={color || "#292B34"}
          mask="url(#d)"
        />
      </g>
    </g>
  </svg>
);
