export const ReasonsparkLogo = ({ color, width, height, style, ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    // width={width || 273}
    width={width}
    height={height || 42.555}
    viewBox="32.25 3.97 273 42.555"
    // enableBackground="new 32.25 3.97 273 42.555"
    // xmlSpace="preserve"
    style={{ ...style }}
    {...rest}
  >
    <g fill={color || "#292B34"}>
      <path d="M45.336 7.035h-9.242V21.22h9.242c4.35 0 7.312-2.967 7.312-7.117 0-4.153-2.962-7.07-7.312-7.07zM43.357 23.79h-7.264v13.642h-2.818V4.464h12.307c5.684 0 10.033 3.608 10.033 9.639 0 5.979-4.25 9.29-9.143 9.488l9.539 13.84h-3.41L43.357 23.79zM61 24.285h17.348c-.051-4.054-2.719-9.095-8.699-9.095-5.635 0-8.454 4.942-8.649 9.095m8.697-11.32c7.266 0 11.27 5.782 11.27 12.654v.689H61c.246 5.19 3.707 9.492 9.34 9.492 3.014 0 5.783-1.137 7.809-3.314l1.334 1.684c-2.371 2.469-5.385 3.854-9.34 3.854-6.969 0-11.961-5.19-11.961-12.553 0-6.921 4.894-12.505 11.515-12.505M101.275 27.496c-1.682-2.175-4.35-3.312-7.217-3.312-3.953 0-6.523 2.523-6.523 5.933 0 3.359 2.57 5.88 6.523 5.88 2.867 0 5.535-1.087 7.217-3.263v-5.238zm0 7.117c-2.125 2.322-4.795 3.41-8.105 3.41-4.102 0-8.352-2.816-8.352-7.96 0-5.187 4.199-7.906 8.352-7.906 3.311 0 6.029 1.088 8.105 3.41v-4.842c0-3.56-2.867-5.486-6.426-5.486-2.965 0-5.287 1.038-7.463 3.509l-1.431-1.78c2.469-2.668 5.238-4.002 9.191-4.002 4.844 0 8.697 2.322 8.697 7.66V37.43h-2.568v-2.818zM109.822 32.34c1.533 1.928 4.35 3.51 7.711 3.51 4.002 0 6.375-1.98 6.375-4.744 0-3.066-3.262-3.955-6.672-4.844-4.004-.99-8.402-2.027-8.402-6.672 0-3.658 3.113-6.623 8.5-6.623 4.104 0 6.82 1.58 8.551 3.509l-1.434 1.827c-1.383-1.877-3.953-3.162-7.117-3.162-3.705 0-5.979 1.828-5.979 4.3 0 2.718 3.062 3.46 6.375 4.299 4.102 1.039 8.697 2.175 8.697 7.267 0 3.854-2.965 7.018-8.945 7.018-3.854 0-6.82-1.137-9.191-3.756l1.531-1.93zM150.396 25.47c0-5.29-3.113-10.182-8.748-10.182-5.535 0-8.699 4.893-8.699 10.182 0 5.338 3.164 10.229 8.699 10.229 5.635 0 8.748-4.89 8.748-10.23m-20.214 0c0-6.97 4.498-12.503 11.467-12.503 7.066 0 11.516 5.534 11.516 12.504 0 6.969-4.449 12.553-11.516 12.553-6.969 0-11.467-5.583-11.467-12.553M174.412 21.269c0-4.597-2.322-5.98-5.781-5.98-3.066 0-6.229 1.928-7.76 4.102v18.04h-2.57V13.558h2.57v3.608c1.729-2.076 5.139-4.201 8.6-4.201 4.842 0 7.512 2.372 7.512 7.76V37.43h-2.57V21.269z" />
    </g>
    <path
      fill={color || "#292B34"}
      d="M185.344 27.447a14.448 14.448 0 0 0 10.477 4.447c3.855 0 5.734-1.83 5.734-3.707 0-2.471-2.867-3.311-6.674-4.2-5.387-1.235-12.307-2.72-12.307-10.083 0-5.484 4.746-9.934 12.504-9.934 5.24 0 9.59 1.582 12.852 4.597l-3.904 5.14c-2.671-2.471-6.229-3.608-9.44-3.608-3.164 0-4.846 1.384-4.846 3.36 0 2.225 2.77 2.917 6.574 3.806 5.438 1.236 12.355 2.867 12.355 10.182 0 6.029-4.301 10.576-13.195 10.576-6.326 0-10.873-2.127-13.938-5.238l3.808-5.338zM223.445 18.55c-2.027 0-4.35 1.188-5.389 2.718v8.45c1.039 1.485 3.361 2.72 5.389 2.72 3.607 0 6.029-2.816 6.029-6.968.001-4.102-2.421-6.92-6.029-6.92zm-11.664 27.975V13.558h6.275v3.016c1.828-2.323 4.449-3.607 7.365-3.607 6.127 0 10.576 4.546 10.576 12.503s-4.449 12.554-10.576 12.554c-2.816 0-5.436-1.186-7.365-3.656v12.158h-6.275zM253.539 28.733c-1.037-1.385-3.016-2.076-5.041-2.076-2.471 0-4.498 1.285-4.498 3.607 0 2.225 2.027 3.51 4.498 3.51 2.025 0 4.004-.691 5.041-2.076v-2.965zm0 6.226c-1.631 1.928-4.447 3.064-7.562 3.064-3.805 0-8.303-2.57-8.303-7.906 0-5.586 4.498-7.613 8.303-7.613 3.164 0 5.982.989 7.562 2.916v-3.311c0-2.422-2.074-4.004-5.238-4.004-2.57 0-4.943.939-6.969 2.817l-2.373-4.201c2.916-2.57 6.674-3.756 10.43-3.756 5.436 0 10.428 2.175 10.428 9.045v15.419h-6.277v-2.47zM264.557 13.558h6.277v3.263c1.68-2.125 4.646-3.806 7.611-3.806v6.129a7.867 7.867 0 0 0-1.73-.198c-2.074 0-4.844 1.186-5.881 2.718v15.768h-6.277V13.558z"
    />
    <g transform="translate(288.855 7.325)">
      <defs>
        <filter
          id="a"
          filterUnits="userSpaceOnUse"
          x={-7.992}
          y={-2.859}
          width={23.33}
          height={32.964}
        >
          <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0" />
        </filter>
      </defs>
      <path
        fill={color || "#292B34"}
        d="m1.152 20.914-2.865 3.062v6.129h-6.279V-2.859h6.279v19.718L7.33 6.233h7.711L5.602 17.058l9.736 13.047H7.479z"
      />
    </g>
  </svg>
);

export const Bulb = ({ color, width, height, style, ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || 40}
    height={height || 54.592}
    xmlSpace="preserve"
    viewBox="0 0 40 54.592"
    style={{ ...style }}
    {...rest}
  >
    <g stroke={color || "#000"} strokeWidth={0.25} strokeMiterlimit={10}>
      <path
        fill="#EC7125"
        d="m20.596 30.798-.678.306-2.488 8.778 2.953-.764-.427 7.84.689-.531 1.929-10.213-2.825.801z"
      />
      <path
        fill={color || "#2A2C35"}
        d="M30.473 15.852a5.405 5.405 0 0 0-5.396-5.339 5.406 5.406 0 0 0-4.863 3.062c-.929-1.647-2.82-2.762-4.84-2.762-2.979 0-5.534 2.424-5.534 5.403v.028c-2.352.566-4.021 2.82-4.021 5.368 0 2.979 2.555 5.521 5.535 5.521h.293c.971 1.176 2.646 2.284 4.515 2.284 2.979 0 5.67-2.393 5.433-5.662v-.153l-.479.004-.02.276c0 2.288-2.608 4.021-4.896 4.021-2.29 0-4.937-1.946-4.937-4.297h-.406c-.137.752-.021 1.334.167 1.879C8.889 25.315 7.2 23.6 7.2 21.422c0-2.288 1.97-4.113 4.258-4.113s4.964 1.904 4.964 4.192l.021.34h.45v-.345c0-2.979-2.488-5.401-5.467-5.404.068-2.23 1.864-4.154 4.111-4.154 2.288 0 4.025 2.235 4.367 4.025h.808v-.049c.388-2.494 2.117-4.127 4.405-4.127s4.107 1.824 4.107 4.176l.011.377v.799h.312c2.288 0 4.149 1.801 4.149 4.09s-1.862 4.121-4.149 4.121c-1.007 0-2.092-.363-2.841-.925-.729-.545-1.533-1.315-1.585-3.172v-.587h-.401s-.268.771-.207 1.605c.051.937.269 1.766.879 2.457-.582 1.592-2.037 3.51-3.799 3.51v.477c2.352 0 4.021-1.274 4.883-3.104a5.378 5.378 0 0 0 3.068.959 5.408 5.408 0 0 0 5.404-5.403c0-2.661-1.936-4.874-4.475-5.315z"
      />
      <path
        fill={color || "#2A2C35"}
        d="M20.001.704c-10.254 0-18.593 8.344-18.593 18.6 0 6.41 3.218 12.237 8.63 15.676.049.273 1.423 7.635 1.899 11.369l.017.15.058.492.09.629 3.354 2.803c.883.729 1.585 1.293 2.133 1.727l.713.557.275.209.41.295.97.678.06-.039.649-.449.397-.279.23-.168.135-.102.678-.521c.533-.42 1.242-.984 2.158-1.74l3.554-2.963.09-.631.028-.256 2.045-11.777c5.391-3.445 8.611-9.261 8.611-15.655C38.594 9.047 30.26.704 20.001.704zm8.701 33.024-3.241 1.637h2.6L26.399 46.65l-6.44 4.939-6.44-4.939-1.625-11.285h2.717l-3.28-1.637c-5.108-3.088-8.16-8.479-8.16-14.418 0-9.289 7.56-16.848 16.849-16.848s16.845 7.561 16.845 16.85c-.006 5.937-3.054 11.328-8.163 14.416z"
      />
    </g>
  </svg>
);
