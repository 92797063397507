import { useState, useMemo, useEffect } from "react";
import {
  ColorSchemeProvider,
  MantineProvider,
  AppShell,
  Overlay,
  Burger,
  Center,
  Text,
  Stack,
  Button,
} from "@mantine/core";
import "@fontsource/nunito-sans/800.css";
import "@fontsource/nunito-sans/200.css";
import "@fontsource/open-sans";
import "@fontsource/open-sans/variable.css";
import { useColorScheme, useMediaQuery, useScrollLock } from "@mantine/hooks";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { IDENTITIES, IDENTITY_PROFILES } from "./identityProfiles";
import { IconSunHigh, IconMoon } from "@tabler/icons";
import { ScrollToTop } from "./components/ScrollToTop";
import { Header, MainLogo } from "./components/Header";
import { Footer } from "./components/Footer";
import { HomePage } from "./pages/home";
// import { WorkPage } from "./pages/work";
import { ServicesPage } from "./pages/services";
import { ApproachPage } from "./pages/approach";
import { ContactPage } from "./pages/contact";
import { NotFound } from "./pages/notfound";

/* -=[ SITE IDENTITY ]=- */
const CURRENT_IDENTITY = IDENTITIES.REASONSPARK;
const IDENTITY_INFO = IDENTITY_PROFILES[CURRENT_IDENTITY];

export const routes = [
  {
    path: `/`,
    element: <HomePage IDENTITY_INFO={IDENTITY_INFO} />,
    pageTitle: "Home",
    mainNav: false,
  },
  /* {
    path: `/work`,
    element: <WorkPage IDENTITY_INFO={IDENTITY_INFO} />,
    pageTitle: "Work",
    mainNav: true,
  }, */
  {
    path: `/services`,
    element: <ServicesPage IDENTITY_INFO={IDENTITY_INFO} />,
    pageTitle: "Services",
    mainNav: true,
  },
  {
    path: `/approach`,
    element: <ApproachPage IDENTITY_INFO={IDENTITY_INFO} />,
    pageTitle: "Approach",
    mainNav: true,
  },
  {
    path: `/contact`,
    element: <ContactPage IDENTITY_INFO={IDENTITY_INFO} />,
    pageTitle: "Contact",
    mainNav: false,
  },
  {
    path: `*`,
    element: <NotFound IDENTITY_INFO={IDENTITY_INFO} />,
    pageTitle: "Not Found",
    mainNav: false,
  },
];

const customColors = {
  white: "#FFFFFF",
  offWhite: "#F6F6EC",
  black: "#111111",
  offBlack: "#2b2b2f",
};

export const App = () => {
  const preferredColorScheme = useColorScheme();
  const [colorScheme, setColorScheme] = useState(preferredColorScheme);
  const toggleColorScheme = (value) =>
    setColorScheme(value || (colorScheme === "dark" ? "light" : "dark"));
  const dark = colorScheme === "dark";
  const [scrollLocked, setScrollLocked] = useScrollLock(); // eslint-disable-line no-unused-vars

  const smSize = useMediaQuery("(max-width: 767px)");
  const xsSize = useMediaQuery("(max-width: 576px)");

  // main menu
  const [menuOpen, setMenuOpen] = useState(false);
  const menuTitle = menuOpen ? "Close navigation" : "Open navigation";

  useEffect(() => {
    setColorScheme(preferredColorScheme);
  }, [preferredColorScheme]);

  const HeaderMemo = useMemo(() => Header, []);

  useEffect(() => {
    if (!!menuOpen) {
      setScrollLocked(true);
    } else {
      setScrollLocked(false);
    }
  }, [menuOpen, setScrollLocked]);

  return (
    <BrowserRouter>
      <ScrollToTop setScrollLocked={setScrollLocked} />
      <ColorSchemeProvider
        colorScheme={colorScheme}
        toggleColorScheme={toggleColorScheme}
      >
        <MantineProvider
          withGlobalStyles
          withNormalizeCSS
          theme={{
            colorScheme,
            colors: {
              ...customColors,
            },
            fontFamily: "Open Sans, sans-serif",
            fontWeight: "400",
            lineHeight: 1.5,

            headings: {
              fontFamily: "Nunito Sans, sans-serif",
              fontWeight: "800",
              sizes: {
                h1: { fontSize: !smSize ? 34 : 28, lineHeight: 1.1 },
                h2: { fontSize: !smSize ? 26 : 21, lineHeight: 1.2 },
                h3: { fontSize: !smSize ? 22 : 18, lineHeight: 1.3 },
                h4: { fontSize: !smSize ? 18 : 15, lineHeight: 1.4 },
                h5: { fontSize: !smSize ? 16 : 13, lineHeight: 1.5 },
                h6: { fontSize: !smSize ? 14 : 11, lineHeight: 1.5 },
              },
            },

            globalStyles: () => ({
              body: {
                minWidth: 280,
              },
            }),

            components: {
              Button: {
                defaultProps: {
                  size: !smSize ? "xl" : !xsSize ? "lg" : "md",
                  radius: "xl",
                  pl: !smSize ? 64 : !xsSize ? 32 : 16,
                  pr: !smSize ? 64 : !xsSize ? 32 : 16,
                  style: { fontFamily: "Nunito Sans, sans-serif" },
                },
              },
              Container: {
                defaultProps: {
                  size: "lg",
                },
              },
              Text: {
                defaultProps: {
                  size: !smSize ? "md" : "sm",
                },
              },
              Title: {
                defaultProps: {
                  fontWeight: 800,
                },
              },
            },

            other: {
              spacing: !smSize ? "6em" : !xsSize ? "4em" : "2em",
              spacingHeader: !xsSize ? "6em" : "4em",
            },
          }}
        >
          <AppShell
            header={
              <HeaderMemo
                IDENTITY_INFO={IDENTITY_INFO}
                menuOpen={menuOpen}
                setMenuOpen={setMenuOpen}
                menuTitle={menuTitle}
              />
            }
            padding={0}
            fixed={false}
            styles={{ main: { position: "relative" } }}
            sx={{
              backgroundColor:
                colorScheme === "dark"
                  ? customColors.black
                  : customColors.white,
            }}
          >
            <Helmet>
              {/* Basics */}
              <meta charSet="utf-8" />
              <title>{IDENTITY_INFO.meta.siteTitle}</title>
              <link
                rel="icon"
                type="image/svg+xml"
                href="/reasonspark-favicon.svg"
              />
              <link
                rel="icon"
                type="image/png"
                href="/reasonspark-favicon.png"
              />
              <meta
                name="description"
                content={IDENTITY_INFO.meta.siteDescription}
              />
              <meta name="keywords" content={IDENTITY_INFO.seo.keywords} />

              {/* Open Graph */}
              <meta
                property="og:title"
                content={IDENTITY_INFO.meta.siteTitle}
              />
              <meta
                property="og:description"
                content={IDENTITY_INFO.meta.siteDescription}
              />
              <meta property="og:type" content="website" />
              <meta
                property="og:url"
                content={`https://${IDENTITY_INFO.meta.domain}/`}
              />
              <meta
                property="og:image"
                content={`/${IDENTITY_INFO.meta.businessName.toLowerCase()}-preview.jpg`}
              />
              <meta property="og:locale" content="en_US" />

              <meta name="twitter:card" content="summary_large_image" />
              <meta
                property="twitter:domain"
                content={IDENTITY_INFO.meta.domain}
              />
              <meta
                property="twitter:url"
                content={`https://${IDENTITY_INFO.meta.domain}/`}
              />
              <meta
                name="twitter:title"
                content={IDENTITY_INFO.meta.siteTitle}
              />
              <meta
                name="twitter:description"
                content={IDENTITY_INFO.meta.siteDescription}
              />
              <meta
                name="twitter:image"
                content={`/${IDENTITY_INFO.meta.businessName.toLowerCase()}-preview.jpg`}
              />

              {/* Google Analytics */}
              <script
                async
                src={`https://www.googletagmanager.com/gtag/js?id=${IDENTITY_INFO.meta.gaTagId}`}
              ></script>
              <script>{`
                window.dataLayer = window.dataLayer || [];
                function gtag() {
                  dataLayer.push(arguments);
                }
                gtag("js", new Date());
                gtag("config", "${IDENTITY_INFO.meta.gaTagId}");
              `}</script>
            </Helmet>

            <Routes>
              {routes.map((thisRoute, i) => (
                <Route
                  path={thisRoute.path}
                  element={thisRoute.element}
                  key={i}
                />
              ))}
            </Routes>

            <Footer IDENTITY_INFO={IDENTITY_INFO} dark={dark} />

            <Overlay
              color={dark ? customColors.offBlack : customColors.offWhite}
              sx={{
                zIndex: menuOpen ? 2000 : -1,
                opacity: menuOpen ? 1 : 0,
                transition: menuOpen
                  ? `z-index 0ms linear 0ms, opacity 200ms ease-in-out 1ms`
                  : `z-index 0ms linear 2010ms, opacity 200ms ease-in-out 0ms`,
                "@media screen and (min-width: 768px)": {
                  display: "none",
                },
              }}
            >
              <Center style={{ height: "100vh" }}>
                <Stack spacing="xs">
                  {routes.map((thisRoute, i) => {
                    if (!thisRoute.mainNav && thisRoute.pageTitle !== "Home")
                      return null;
                    return (
                      <Text
                        component={Link}
                        to={thisRoute.path}
                        align="center"
                        onClick={() => setMenuOpen(false)}
                        style={{
                          fontFamily: '"Nunito Sans", sans-serif',
                          fontSize: 48,
                          margin: "auto",
                        }}
                        key={i}
                      >
                        {thisRoute.pageTitle}
                      </Text>
                    );
                  })}
                  <Button
                    component={Link}
                    to="/contact"
                    onClick={() => setMenuOpen(false)}
                    mt="1em"
                    mb="1em"
                    pl={64}
                    pr={64}
                    size="lg"
                    sx={{
                      backgroundColor: dark
                        ? customColors.black
                        : customColors.white,
                      color: dark
                        ? customColors.offWhite
                        : customColors.offBlack,
                      transition: `all 100ms ease-in-out 0ms`,
                      marginLeft: "auto",
                      marginRight: "auto",
                      "&:hover": {
                        backgroundColor: dark
                          ? customColors.offWhite
                          : customColors.offBlack,
                        color: dark ? customColors.black : customColors.white,
                      },
                    }}
                  >
                    Contact Us
                  </Button>
                  <Button
                    color="gray"
                    variant="subtle"
                    onClick={() => toggleColorScheme()}
                    style={{ margin: "auto" }}
                    size="sm"
                    pl="md"
                    pr="md"
                    leftIcon={
                      dark ? (
                        <IconSunHigh size="1.1em" aria-label="Dark mode icon" />
                      ) : (
                        <IconMoon size="1.1em" aria-label="Light mode icon" />
                      )
                    }
                  >
                    {dark ? "light" : "dark"} mode
                  </Button>
                </Stack>
              </Center>
            </Overlay>
            <MainLogo
              IDENTITY_INFO={IDENTITY_INFO}
              dark={dark}
              height={30}
              style={{
                position: "absolute",
                top: 19 + 3,
                left: 20,
                zIndex: 2000,
              }}
              sx={{
                display: "none !important",
                "@media (max-width: 767px)": {
                  display: "block !important",
                },
              }}
              onClick={() => setMenuOpen(false)}
              aria-label={`${IDENTITY_INFO.meta.businessName} logo`}
            />
            <Burger
              color={dark ? "white" : "black"}
              size={26}
              opened={menuOpen}
              onClick={() => setMenuOpen((o) => !o)}
              title={menuTitle}
              sx={{
                position: "absolute",
                top: 20,
                right: 20,
                zIndex: 2000,
                "@media screen and (min-width: 768px)": {
                  display: "none",
                },
              }}
            />
          </AppShell>
        </MantineProvider>
      </ColorSchemeProvider>
    </BrowserRouter>
  );
};

export default App;
